.container {
  position: relative;
  width: 80%;
  max-width: 650px;
  background-color: white;
  border-radius: 8px;
  padding: 32px;
  cursor: default;
}

.containerSlim {
  width: 30%;
}

.over {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  height: 100vh;
  width: 100vw;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.header {
  display: flex;
  justify-content: flex-end;
}

.close:hover {
  fill: black;
  cursor: pointer;
}

.buttonContainer {
  display: flex;
  gap: 30px;
  /*justify-content: flex-end;*/
  justify-content: center;
  margin-top: 20px;
  margin-right: 20px;
}

.modalContent {
  max-height: 80vh;
  overflow-y: auto;
}

@media (max-width: 600px) {
  .container {
    min-width: 300px;
  }
}
