.App {
  text-align: center;
}

.link {
  color: #333;
  text-decoration: none;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}

.MuiTableSortLabel-root:hover {
  color: whitesmoke !important;
}

.MuiTableSortLabel-root.MuiTableSortLabel-active {
  color: white !important;
  text-decoration: white underline;
}

.MuiTableCell-root {
  padding: 12px !important;
}

.MuiDrawer-paperAnchorRight {
  padding: 1rem 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.MuiPaper-elevation4 {
  box-shadow: none !important;
}
