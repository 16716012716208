.flex {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gridOne {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr;
}

.gridTwo {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.gridThreeFour {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(auto-fill, 1fr);
}

.gridFiveSix {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-flow: row;
  grid-template-areas:
    '. . .'
    '. . .';
}

.videoCell {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 780px) {
  .flex {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow: scroll;
  }
  .gridTwo {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .gridThreeFour {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .gridFiveSix {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}
