@font-face {
  font-family: 'itc-avant';
  src:
    local('itc-avant'),
    url(./fonts/itcavantgarde/l27f4-azcie.woff2) format('woff2'),
    url(./fonts/itcavantgarde/wc229-sekw9.woff) format('woff');
}

body {
  margin: 0;
  background-color: #f6f6f6;
  font-family: 'itc-avant', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
.MuiTypography-h1,
h2,
.MuiTypography-h2,
h3,
.MuiTypography-h3,
h4,
.MuiTypography-h4,
h5,
.MuiTypography-h5,
h6,
.MuiTypography-h6,
.MuiTypography-subtitle1,
.MuiTypography-subtitle2,
.MuiTypography-body2,
.MuiTypography-body1,
.MuiTypography-caption,
code,
.MuiTableCell-root,
.MuiInputBase-root,
.MuiButton-root,
.MuiFormLabel-root {
  font-family: 'itc-avant', sans-serif !important;
}

.MuiButtonBase-root {
  border: 0 !important;
  border-radius: 0 !important;
}
.MuiPaper-root {
  border-radius: 0 !important;
}
.MuiButtonGroup-contained,
.MuiButton-containedPrimary {
  box-shadow: none !important;
}
.MuiButtonGroup-contained :hover {
  box-shadow: none !important;
}
.MuiButton-containedPrimary {
  color: rgba(0, 0, 0, 0.16) !important;
  box-shadow: none;
  background-color: rgba(0, 0, 0, 0.26) !important;
}
